import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import VuexRouterSync from 'vuex-router-sync'
import App from './App'
import JQuery from 'jquery';
import ElementUi from 'element-ui';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import VueCarousel from 'vue-carousel';
import VueMoment from 'vue-moment';
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts'

window.$ = JQuery;

import '@/plugins'
import '@/validators'
import '@/directives'
import '@/locales'
import '@/components'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../public/css/fonts.css';
import '../public/css/profile.css';
import '../public/css/main.css';
import '../public/css/media.css';
import '../public/css/profile-mob.css';
import '../public/css/statistics.css';
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

VuexRouterSync.sync(store, router)

Vue.use(VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(ElementUi, {locale})
Vue.use(VueCarousel)

Vue.filter('currency', function (value, currency = '₸') {
  if (!value) return ''
  return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + currency;
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  ...App
})
