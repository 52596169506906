import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import NavbarNoBurger from '@/components/NavbarNoBurger'
import Footer from '@/components/Footer'
import Sidebar from '@/components/Sidebar'
import SidebarParent from '@/components/SidebarParent'
import ProfileFooter from "@/components/ProfileFooter";
import NavbarManager from  "@/components/NavbarManager"
import NavbarNew from "@/components/NavbarNew.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import FooterNew from "@/components/FooterNew.vue";
import FooterNewStraight from "@/components/FooterNewStraight.vue";
import NavbarNewLight from "@/components/NavbarNewLight.vue";
import FooterNewStraightLight from "@/components/FooterNewStraightLight.vue";


export default [
    {
        path: '/old-main',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Index')
        }
    },
    {
        path: '/',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/NewMain.vue')
        }
    },
    // {
    //     path: '/signin',
    //     components: {
    //         header: Navbar,
    //         footer: Footer,
    //         default: () => import('@/views/Auth/Signin')
    //     }
    // },
    // {
    //     path: '/signup-parent',
    //     name: 'signup-parent',
    //     components: {
    //         header: Navbar,
    //         footer: Footer,
    //         default: () => import('@/views/Auth/Signup/Index')
    //     }
    // },
    // {
    //     path: '/signup',
    //     components: {
    //         header: Navbar,
    //         footer: Footer,
    //         default: () => import('@/views/Auth/signup-1-step')
    //     }
    // },
    // {
    //     path: '/signup-2-step',
    //     components: {
    //         header: Navbar,
    //         footer: Footer,
    //         default: () => import('@/views/Auth/signup-2-step')
    //     }
    // },

    {
        path: '/forget-password',
        components: {header: NavbarNew, footer: FooterNew, default: () => import('@/views/Auth/ForgetPassword/Index')}
    },
    {
        path: '/forget-password/sms',
        components: {header: NavbarNew, footer: FooterNew, default: () => import('@/views/Auth/ForgetPassword/Sms')}
    },
    {
        path: '/forget-password/email',
        components: {header: NavbarNew, footer: FooterNew, default: () => import('@/views/Auth/ForgetPassword/Email')}
    },
    {
        path: '/reset-password',
        components: {header: Navbar, footer: Footer, default: () => import('@/views/Auth/ResetPassword')}
    },
    // {
    //     path: '/signup',
    //     name: 'signup',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Auth/Signup/Index')
    //     }
    // },
    // {
    //     path: '/about',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Home/About')
    //     }
    // },
    // {
    //     path: '/for-members',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Home/ForMembers')
    //     }
    // },
    {
        path: '/for-members',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/ForMembersNew')
        }
    },
    // {
    //     path: '/for-teachers',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Home/ForTeachers')
    //     }
    // },
    // {
    //     path: '/for-parents',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Home/ForParents')
    //     }
    // },
    {
        path: '/regions',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/Regions')
        }
    },
    // {
    //     path: '/faq',
    //     components: {
    //         header: Navbar, footer: Footer, default: () => import('@/views/Home/Faq')
    //     }
    // },
    {
        path: '/moderation-policy',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/ModerationPolicy')
        }
    },
    {
        path: '/terms-of-use',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/TermsOfUse')
        }
    },
    {
        path: '/privacy-policy',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/PrivacyPolicy')
        }
    },
    {
        path: '/library-regulations',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/LibraryRegulations')
        }
    },
    {
        path: '/public-offer',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/PublicOffer')
        }
    },
    {
        path: '/personal-data',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/PersonalData')
        }
    },
    {
        path: '/test-results',
        name: 'test-results',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/TestResults')
        }
    },
    {
        path: '/test-results-new/:id/:token',
        name: 'test-results-new',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/TestResultsNew')
        }
    },
    {
        path: '/profqabilet/:id/:token',
        name: 'profqabilet',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/Profqabilet')
        }
    },
    {
        path: '/test-results-15-17/:id/:token',
        name: 'test-results-15-17',
        components: {
            header: NavbarNew,
            default: () => import('@/views/Home/TestResults15-17')
        }
    },
    {
        path: '/test-results7-9',
        name: 'test-results7-9',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/TestResults7-9')
        }
    },
    {
        path: '/test-results-second',
        name: 'test-results-second',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/TestResulstSecond')
        }
    },
    {
        path: '/test-results-old',
        name: 'test-results-old',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/TestResultsOld')
        }
    },
    {
        path: '/profile-parent',
        components: {
            sidebar: SidebarParent, default: () => import('@/views/ProfileParent/Index')
        }
    },
    {
        path: '/children',
        components: {
            sidebar: SidebarParent, default: () => import('@/views/ProfileParent/Children')
        }
    },
    {
        path: '/settings-parent',
        components: {
            sidebar: SidebarParent, default: () => import('@/views/ProfileParent/Settings')
        }
    },
    {
        path: '/surveys-parent',
        components: {
            sidebar: SidebarParent, default: () => import('@/views/ProfileParent/Surveys')
        }
    },
    {
        path: '/profile',
        components: {
            sidebar: Sidebar,
            //   footer: Footer,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Index')

        }
    },
    {
        path: '/parent',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Parent')
        }
    },
    {
        path: '/testing',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Testing')
        }
    },
    {
        path: '/quizzes-list',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/SelectTesting')
        }
    },
    {
        path: '/profqabilet',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/ProfQabilet')
        }
    },
    {
        path: '/profqabilet-result/:id/:token',
        name: 'profqabilet',
        components: {
            header: NavbarNoBurger,
            default: () => import('@/views/Home/Profqabilet')
        }
    },
    {
        path: '/notes',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Notes')
        }
    },
    {
        path: '/my-consultations',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/MyConsultations')
        }
    },
    {
        path: '/results',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Results')
        }
    },
    {
        path: '/notification',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Notification')
        }
    },
    {
        path: '/settings',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Settings')
        }
    },
    {
        path: '/surveys',
        components: {
            sidebar: Sidebar,
            profilefooter: ProfileFooter,
            default: () => import('@/views/Profile/Surveys')
        }
    },
    {
        path: '/cabinet',
        components: {
            sidebar: Sidebar, default: () => import('@/views/Profile/Cabinet')
        }
    },
    {
        path: '/authorization-manager',
        components: {header: NavbarManager , default: () => import('@/views/managerProfile/authorization')}
    },
    {
        path: '/profile-manager',
        components: {header: NavbarManager ,  default: () => import('@/views/managerProfile/profile')}
    },
    {
        path: '/exercises',
        components: {
            header: Navbar, footer: Footer, default: () => import('@/views/Home/Exercises')
        }
    },
    {
        path: '/for-parents',
        name: 'parents-new-block',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/ForParentsNew.vue')
        }
    },
    {
        path: '/about-us',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/AboutNew')
        }
    },
    {
        path: '/statistics',
        components: {
            default: () => import('@/views/Home/Statistics')
        }
    },
    {
        path: '/registry',
        components: {
            default: () => import('@/views/Home/Registry')
        }
    },
    {
        path: '/centers',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/Centers')
        }
    },
    {
        path: '/signin',
        components: {
            header: NavbarNewLight,
            footer: FooterNewStraightLight,
            default: () => import('@/views/Auth/SigninNew')
        }
    },
    {
        path: '/signup',
        components: {
            header: NavbarNewLight,
            footer: FooterNewStraightLight,
            default: () => import('@/views/Auth/signup-new-1-step.vue')
        }
    },
    {
        path: '/parents-list/:id',
        name: 'parents-list',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/ParentsList.vue')
        }
    },
    {
        path: '/teachers-list/:id',
        name: 'teachers-list',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/TeachersList')
        }
    },
    {
        path: '/signup-2',
        components: {
            header: NavbarNewLight,
            footer: FooterNewStraightLight,
            default: () => import('@/views/Auth/signup-new-2-step.vue')
        }
    },
    {
        path: '/signup-parents',
        components: {
            header: NavbarNewLight,
            footer: FooterNewStraightLight,
            default: () => import('@/views/Auth/signup-parents.vue')
        }
    },
    {
        path: '/for-teachers',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/ForTeachersNew')
        }
    },
    {
        path: '/library',
        name: 'library',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/Library')
        }
    },
    {
        path: '/book/:id',
        name: 'book',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/Book')
        }
    },
    {
        path: '/events',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/NewEvents')
        }
    },
    {
        path: '/forums',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/NewForums')
        }
    },
    {
        path: '/seminars',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/NewSeminars')
        }
    },
    {
        path: '/trainings',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/NewTrainings')
        }
    },
    {
        path: '/one-forum/:id',
        name: 'one-forum',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/OneForum')
        }
    },
    {
        path: '/one-seminar/:type/:id',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/OneSeminar')
        }
    },
    {
        path: '/one-training/:type/:id',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/OneTraining')
        }
    },
    {
        path: '/faq',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: () => import('@/views/Home/Faq-new.vue')
        }
    },
    {
        path: '/news',
        components: {
            header: NavbarNew, footer: FooterNew, default: () => import('@/views/Home/NewsList')
        }
    },
    {
        path: '*',
        components: {
            header: NavbarNew,
            footer: FooterNew,
            default: Empty
        }

    }
]
